import { useQuery } from "@apollo/client";
import { MerchCouponsQueryDocument, MerchCouponsQueryQueryVariables } from "__generated__/typedefs";

export function useMerchCouponsQuery(variables: MerchCouponsQueryQueryVariables) {
  return useQuery(MerchCouponsQueryDocument, {
    variables,
    ssr: false,
    context: {
      timeout: 15000,
    },
  });
}

export default useMerchCouponsQuery;
