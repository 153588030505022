import * as React from "react";
import { observer } from "mobx-react";
import { useBexApi } from "@shared-ui/bex-api-context";
import { CouponsProps, CouponsQueryComponentProps, CouponContent } from "./typings";
import { withStores } from "stores";
import { UitkLayoutConditionalGridSpan, UitkLayoutGrid, UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { UitkSpacing } from "@egds/react-core/spacing";
import { CouponsFlexModuleResult } from "src/typings/microserviceModels/coupons-flex-module";
import { useMerchCouponsQuery } from "./hooks/useMerchCouponsQuery";
import { CouponCard } from "./components/CouponCard";
import { getFmId } from "src/stores/ExperienceTemplateStore";

const Coupons = withStores("flexModuleModelStore")(
  observer((props: CouponsProps) => {
    const { templateComponent, flexModuleModelStore } = props;

    /* istanbul ignore if */
    if (!flexModuleModelStore || !templateComponent) {
      return null;
    }

    const {
      metadata: { id },
    } = templateComponent;

    const model = flexModuleModelStore.getModel(id) as CouponsFlexModuleResult | null;

    if (!model || !model.coupons) {
      return null;
    }

    return <CouponsQueryComponent model={model} templateComponent={templateComponent} />;
  })
);

const CouponsQueryComponent: React.FC<CouponsQueryComponentProps> = (props) => {
  const { model, templateComponent } = props;
  const {
    metadata: { id },
  } = templateComponent;
  const fmId = getFmId(templateComponent);
  const { coupons } = model;
  const { context } = useBexApi();

  const variables = {
    context,
    couponIds: coupons.map((coupon) => coupon.campaignId),
  };

  const { data, error, loading } = useMerchCouponsQuery(variables);

  if (error || loading) {
    return null;
  }

  /* istanbul ignore next */
  const couponsList: CouponContent[] = model.coupons
    .map((coupon) => {
      const graphqlCoupon = data?.merchCoupons?.coupons.find(({ id }) => id === coupon.campaignId);

      const { title, subTitle, terms, campaignId } = coupon;

      if (!graphqlCoupon) return null;

      return {
        ...graphqlCoupon,
        id: graphqlCoupon?.id || campaignId,
        title,
        subTitle,
        terms,
      };
    })
    .filter((coupon): coupon is CouponContent => Boolean(coupon));

  if (couponsList.length === 0) {
    return null;
  }

  const couponGridSpan: UitkLayoutConditionalGridSpan = { medium: 1 };
  const oddCouponSpan: UitkLayoutConditionalGridSpan = { medium: 2 };
  const isOdd = coupons.length % 2 === 1;

  return (
    <UitkSpacing margin={{ blockend: "six" }}>
      <div data-testid="coupons" className="Coupons" id={id} data-fm={fmId}>
        <UitkSpacing margin={{ blockstart: "six" }}>
          <div data-testid="coupons-grid">
            <UitkSpacing margin={{ blockstart: "three", inline: "three" }}>
              <UitkLayoutGrid columns={{ medium: 2, extra_large: 2, small: 1 }} space="three">
                {couponsList.map((coupon, index) => {
                  const colSpan = isOdd && index === coupons.length - 1 ? oddCouponSpan : couponGridSpan;

                  return (
                    <UitkLayoutGridItem colSpan={colSpan} key={`coupon-${coupon.id}`} data-testid="coupon-item">
                      <div>
                        <CouponCard couponContent={coupon} />
                      </div>
                    </UitkLayoutGridItem>
                  );
                })}
              </UitkLayoutGrid>
            </UitkSpacing>
          </div>
        </UitkSpacing>
      </div>
    </UitkSpacing>
  );
};

export default Coupons;
