import * as React from "react";
import { UitkCard, UitkCardContentSection } from "@egds/react-core/cards";
import { UitkHeading, UitkText } from "@egds/react-core/text";
import { UitkLink } from "@egds/react-core/link";
import { UitkSpacing } from "@egds/react-core/spacing";
import { CouponContent } from "../typings";
import { UitkLayoutFlexItem } from "@egds/react-core/layout-flex";

interface CouponCardProps {
  couponContent: CouponContent;
}

export const CouponCard: React.FC<CouponCardProps> = ({ couponContent }) => (
  <UitkCard border padded>
    <UitkLayoutFlexItem>
      <div>
        <UitkCardContentSection>
          <UitkSpacing padding={{ blockend: "one" }}>
            <div>
              <UitkHeading tag="h3" size={5} align="center">
                {couponContent.title}
              </UitkHeading>
            </div>
          </UitkSpacing>
          <UitkSpacing padding={{ blockend: "one" }}>
            <div>
              <UitkHeading tag="h3" size={7} align="center">
                {couponContent.subTitle}
              </UitkHeading>
            </div>
          </UitkSpacing>
          <UitkSpacing padding={{ blockend: "one" }}>
            <div>
              <UitkHeading tag="h3" size={7} align="center">
                {couponContent.statusMessage}
              </UitkHeading>
            </div>
          </UitkSpacing>
          <UitkSpacing padding={{ blockend: "one" }} margin={{ blockstart: "two" }}>
            <UitkText size={200} align="center">
              {couponContent.terms}
              <UitkSpacing padding={{ blockend: "one" }}>
                <UitkLink align="center">
                  <a href={couponContent.termsAndConditions.informationUrl.value}>
                    {couponContent.termsAndConditions.text}
                  </a>
                </UitkLink>
              </UitkSpacing>
            </UitkText>
          </UitkSpacing>
        </UitkCardContentSection>
      </div>
    </UitkLayoutFlexItem>
  </UitkCard>
);

export default CouponCard;
